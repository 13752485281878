import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { CircularProgress, Backdrop, Button } from "@mui/material";
const admin_service = require("../../helpers/admin_service");

const Tarifas = () => {
  const [evento, setEvento] = useState({});
  const [loading, setLoading] = useState(true);
  const [contador] = useState(0);
  useEffect(() => {
    admin_service
      .getData(`/evento/view-by-hash/${process.env.REACT_APP_EVT}`)
      .then((response_evt) => {
        setEvento(response_evt.data.response_database.result[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return loading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <img src={evento.flier} loading="lazy" style={{ width: "50%" }} />
    </Box>
  );
};

export default Tarifas;

{
  /*<Box component="section" className="video-section">
          <Grid container></Grid>
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/RESUMEN+CLADIT+2023.mp4"
              type="video/mp4"
            />
          </video>
  </Box>*/
}
