import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import "./style.css";
import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import Button from "@mui/material/Button";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Patrocinadores from "../patrocinio/Patrocinadores";
import Conferencistas from "../conferencistas/Conferencistas";
import { Modal } from "@mui/material";
const admin_service = require("../../helpers/admin_service");
const helpers = require("../../helpers/helpers");

const Inicio = () => {
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [patrocinadores, setPatrocinadores] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "transparent",

    p: 4,
  };
  useEffect(() => {
    admin_service
      .getData("/evento/view-by-hash/" + process.env.REACT_APP_EVT)
      .then((response_evt) => {
        admin_service
          .getData("/patrocinador/view-by-evento/" + process.env.REACT_APP_EVT)
          .then((response_patrocinador) => {
            setEvento(response_evt.data.response_database.result[0]);
            setPatrocinadores(response_patrocinador.data.response.result);
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return (
    <Box paddingTop={10}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={4} alignItems="center">
        <Stack spacing={5} alignItems="center" justifyContent="center">
          <Typography variant="h4" style={{ textAlign: "center" }}>
            <EventIcon /> &nbsp;19 de Noviembre
          </Typography>
          <Button
            style={{
              backgroundColor: "#ff6a38",
              color: "white",
              border: "1px solid transparent",
            }}
            variant="outlined"
            startIcon={<DriveFileRenameOutlineIcon />}
            onClick={() => {
              window.open(
                "https://ebg.edu.gt/inscripcion/e273fe64098fc50720c0b8c6446e299e937c551442abc3c973a4c21631044417",
                "_blank"
              );
            }}
          >
            Inscríbete en Línea
          </Button>
        </Stack>

        {/*<video autoPlay muted loop style={{ width: "50%" }}>
          <source
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/2024+CLADIT+3.mp4"
            type="video/mp4"
          />
        </video>*/}
        <Stack alignItems="center" justifyContent="center" spacing={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ textAlign: "justify" }}
          >
            {/*<Grid xs={12} md={6} lg={6} p={3}>
              <Stack spacing={5}>
                <Button
                  onClick={handleTogglePlay}
                  startIcon={isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                  style={{
                    backgroundColor: "#397d51",
                    color: "white",
                    border: "1px solid transparent",
                  }}
                >
                  {isPlaying
                    ? "Click para pausar video"
                    : "Click para reproducir video"}
                </Button>
                <video ref={videoRef} controls style={{ width: "100%" }}>
                  <source
                    src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Video+Jorge+Gonzalez++2.mp4"
                    type="video/mp4"
                  />
                </video>
              </Stack>
            </Grid>*/}
            {evento.video_promo && (
              <video
                autoPlay
                muted
                playsInline
                loop
                style={{
                  width: "100%",
                }}
              >
                <source src={evento.video_promo} type="video/mp4" />
              </video>
            )}
            <Grid xs={12} md={6} lg={6} p={3}>
              <Typography variant="h4">
                ¿Qué es el Financial Comercial Summit?
              </Typography>
              <Typography variant="p">
                <p>
                  La <strong>Asociación Bancaria de Guatemala -ABG-</strong>, a
                  través de sus iniciativas educativas, se dedica a fortalecer
                  el conocimiento y las prácticas en torno a la gestión
                  comercial en el ámbito financiero, la integración de
                  soluciones digitales y las innovaciones tecnológicas en la
                  industria bancaria. Este evento busca reunir a líderes
                  especializados para compartir experiencias e información sobre
                  las tendencias actuales y futuras en estos ámbitos.
                </p>

                <p>
                  Para ello, invita a participar en su{" "}
                  <strong>I Financial Comercial Summit 2024</strong>. Este
                  evento está diseñado para profesionales, académicos y
                  cualquier persona interesada en la evolución del negocio
                  financiero y bancario. Durante el summit, se presentarán
                  conferencias nacionales e internacionales, paneles de
                  discusión, y se proporcionarán espacios para el análisis y la
                  reflexión con expertos en temas como negocios, créditos y
                  ventas, experiencia del cliente (Customer Experience),
                  marketing y cobranza.
                </p>
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <Typography variant="h4">CARDALES DE CAYALÁ</Typography>
              <Typography variant="h5">Salón Azaria</Typography>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18318.566441215426!2d-90.4934621403194!3d14.611968637782246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8589a2fdfe6f4fad%3A0xc546beb32a5912d1!2sCardales%20de%20Cayal%C3%A1%2C%20Cdad.%20de%20Guatemala!5e1!3m2!1ses!2sgt!4v1723576073379!5m2!1ses!2sgt"
                width="75%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Grid>
            <Grid xs={12} md={6} lg={6} style={{ textAlign: "center" }}>
              <img
                src="https://cdn.prod.website-files.com/615f5753a32dcc1e9220daf6/6185881712cf2d037b431ab0_Fotos-filtros2.png"
                style={{ width: "60%", loading: "lazy" }}
              />
            </Grid>
          </Grid>
          <ImageList cols={5}>
            <ImageListItem>
              <img
                src={`https://cdn.prod.website-files.com/615f5754a32dcc094d20db13/6584c80540376b1481995141_20231221T1119-4323cfe6-f138-4b5d-8991-f4afe854a979-p-2000.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://cdn.prod.website-files.com/615f5754a32dcc094d20db13/6584c8224a5d23d0a5bf1a07_20231221T1119-2408a2ce-dee1-41a2-8f17-d23b0bfbe190-p-2000.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://cdn.prod.website-files.com/615f5754a32dcc094d20db13/6584c7ee0cb8a36375a88375_20231221T1118-c30e4e20-7f7f-4898-b3be-0f6a0c20e3f9-p-2000.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://cdn.prod.website-files.com/615f5754a32dcc094d20db13/6584c842b37ac774b2e88d55_20231221T1120-02c7a44b-d93a-44a4-9d37-721adc870cb6-p-2000.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
            <ImageListItem>
              <img
                src={`https://cdn.prod.website-files.com/615f5754a32dcc094d20db13/6584c7aca9428cb1c746b30e_20231221T1117-57c0d861-a601-450e-ad54-642f1cd7c6fc.png`}
                alt="corain-img"
                style={{ width: "100%", objectFit: "cover" }}
                loading="lazy"
              />
            </ImageListItem>
          </ImageList>
          {/* <img src = 'https://escuela-bancaria.s3.us-east-2.amazonaws.com/f476e3ca-9931-4a6e-adda-30f8b0860e2d.jpeg'/>*/}
        </Stack>
        <Conferencistas />
        <Grid container alignItems="center" justifyContent="center">
          <Grid xs={12} md={6} lg={6}>
            <img
              style={{ width: "100%" }}
              src={`https://escuela-bancaria.s3.us-east-2.amazonaws.com/IMG_7454+(2).jpg`}
              alt="cladit-img"
              loading="lazy"
            />
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <Stack spacing={2} style={{ padding: "6%" }}>
              <Typography variant="h5" style={{ textAlign: "center" }}>
                Dirigido a:
              </Typography>
              <Typography
                variant="p"
                style={{ textAlign: "left", fontSize: "0.9em" }}
              >
                <ul className="columns">
                  <li>Directores de Bancos y Entidades Financieras</li>
                  <li>Gerentes de Negocios y Ventas</li>
                  <li>Responsables de Experiencia del Cliente</li>
                  <li>Especialistas en Marketing Financiero</li>
                  <li>Profesionales de Cobranzas</li>
                  <li>Consultores en Tecnología Financiera</li>
                  <li>Desarrolladores de Soluciones Digitales</li>
                  <li>Responsables de Transformación Digital</li>
                  <li>Académicos y Profesionales del Derecho Financiero</li>
                  <li>Ejecutivos de Innovación en la Industria Bancaria</li>
                </ul>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        {patrocinadores.length > 0 && (
          <Patrocinadores
            evt={process.env.REACT_APP_EVT}
            patrocinadores={patrocinadores}
          />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              src={evento.planos}
              alt={evento.hash}
              style={{ width: "100%", objectFit: "contain", height: "100%" }}
              loading="lazy"
            />
          </Box>
        </Modal>
      </Stack>
    </Box>
  );
};

export default Inicio;
