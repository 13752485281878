import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Grid, Button, Modal } from "@mui/material";
import Divider from "@mui/material/Divider";
import React, { useEffect, useState } from "react";
import { CircularProgress, Backdrop, Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
const admin_service = require("../../helpers/admin_service");

const Patrocinio = () => {
  const [evento, setEvento] = useState({});
  const [loading, setLoading] = useState(true);
  const [contador] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    admin_service
      .getData("/evento/view-by-hash/" + process.env.REACT_APP_EVT)
      .then((response_evt) => {
        setEvento(response_evt.data.response_database.result[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "90vh",
    bgcolor: "transparent",

    p: 4,
  };
  return loading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box>
      <Stack spacing={4} style={{ padding: "5%" }}>
        {evento.planos && (
          <Typography variant="h3" style={{ textAlign: "center" }}>
            PLANOS STAND
          </Typography>
        )}
        {evento.planos && (
          <img
            src={evento.planos}
            alt={"planos"}
            style={{ width: "90%" }}
            loading="lazy"
            onClick={handleOpen}
          />
        )}
        <Button
          startIcon={<PictureAsPdfIcon />}
          href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/4df4ce5d-6d2c-4792-a59c-6f6f00397f93.pdf"
          target="_blank"
        >
          Click para ver brief de patrocinios
        </Button>
        <Divider style={{ backgroundColor: "#ff6a38", height: "5px" }} />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <img
              src={evento.planos}
              alt={evento.hash}
              style={{ width: "100%", objectFit: "contain", height: "100%" }}
              loading="lazy"
            />
          </Box>
        </Modal>
      </Stack>
    </Box>
  );
};

export default Patrocinio;

{
  /*<Box component="section" className="video-section">
          <Grid container></Grid>
          <video autoPlay muted loop id="myVideo">
            <source
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/RESUMEN+CLADIT+2023.mp4"
              type="video/mp4"
            />
          </video>
  </Box>*/
}
